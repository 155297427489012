@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');


.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #000;
}

.hover-underline-animation:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: rgb(210 180 140 / var(--tw-text-opacity));;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}


body {
  scroll-behavior:smooth;
  margin: 0;
  font-family: 'Roboto Slab', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Roboto Slab', serif;

}

.container-ap {
  padding-left: 40px;
  padding-right: 40px;
  width: 1200px;
}

.container {
  position: relative;
  align-self: center;
  align-items: center;
  justify-content: center;
  justify-self: center;
  width: 80%;
  height: 200px;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  
}

.overlay-banner{
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(167, 22, 22, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.container:hover .overlay {
  opacity: 1;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}



/* Navbar --start*/
.header {
  position: fixed;
  height: 80px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  transition: .3s ease-in;
  overflow: visible;
}

.header-bg {
  background-color: #185a80f0;
  z-index: 100;
  box-shadow: 2px 2px 4px #000000;
}

.header .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1300px;
  margin: auto;
  height: 100%;
  padding: 0 2rem;
  /* overflow: visible; */
}

.textclass {
  font-size: 0.9rem !important;

}

.nav-item {
  font-size: 1rem;
}

/* .nav-item a:hover{
  color:#F6B803;
} */

.header .navbar img {
  width: 222px;
  height: auto;
}

.header .nav-menu {
  display: flex;
}

.spacingYlogo {
  padding-top: -40px;
}

.spacingYtext {
  padding-top: -30px;
}

.spacinglogo {
  padding-top: 10px;
}

.spacingtext {
  padding-top: 60px;
}

.header .nav-item {
  padding: 0.70rem;
  font-weight: 500;
}

.rajez {
  z-index: 99 !important;
}

.hamburger {
  display: none;
}

.dropdown-menu {
  visibility: visible;
}

.dropdown-mobile {
  display: none;
}

.colr{
  color: black;
  font-size: 17px !important;
  font-weight:600;
}

@media screen and (max-width:940px) {
  .nav-item a {
    color: #fff;
  }

  .header {
    max-width: 100%;
    /* background-color: #185A80; */
  }

  .header .navbar {
    max-width: 100%;
  }


  #col{
    color: white;
    font-size: 30px;
    padding: 0;
  }

  .hamburger {
    display: block;
  }


  .nav-menu {
    position: fixed;
    left: -100%;
    top: 80px;
    flex-direction: column;
    background-color: rgba(0, 0, 0, .9);
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    z-index: 999;
    text-align: center;
    transition: .3s;
  }

  .nav-menu.active {
    left: 0;
  }

  .nav-item {
    margin: 1.5rem 0;
  }

  .header .navbar img {
    width: 150px;
  }

  .dropdown-menu {
    visibility: hidden;
  }

  .dropdown-mobile {
    display: none;
  }
}

/* @media (min-width:) {
  
} */

@media only screen and (min-width: 510px) and (max-width: 620px) {
  .boxessingle {
    margin-top: 4rem;
  }
}

@media only screen and (min-width: 621px) and (max-width: 770px) {
  .boxessingle {
    margin-top: 9rem;
  }
}

@media only screen and (min-width: 510px) and (max-width: 620px) {
  .navMob{
    display: none;
  }
}

@media only screen and (min-width: 320px) and (max-width: 510px) {
  .header .nav-item {
    padding: 0rem;
    font-weight: 500;
  }

  .nav-item {
    margin: 1.3rem 0;
  }



}

/* Navbar--close */


/* partner start*/
.imageClass {
  -webkit-filter: drop-shadow(12px 12px 16px rgba(0,0,0,0.5));
}

/* partner close */


.team-1 {
  background-image: url('https://images.pexels.com/photos/3785084/pexels-photo-3785084.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500')
}

/* scroller */

.scroller {
  overflow-y: scroll;
  scrollbar-width: 3px;
}
.scroller::-webkit-scrollbar {
  /* display: none; */
  width: 6px;
  background:#185A80;
}
.scroller::-webkit-scrollbar-thumb{
  background: #d2b48c;
  border-radius:10px;


}