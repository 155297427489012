.quote-bg {
    background-image: linear-gradient(rgba(37, 34, 39, 0.9),
            rgba(23, 22, 24, 0.9)),
        url(../public/assets/images/CounterBg.jpg);
    background-size: cover;
    background-attachment: fixed;
    height: 250px;

}

.analysis {
    background-image: linear-gradient(rgba(37, 34, 39, 0.9),
            rgba(23, 22, 24, 0.9)),
        url(../public/assets/images/financial.jpg);
    background-size: cover;
    background-attachment: fixed;
    height: 250px;
}

.readmore {
    color: #fff;
    border: 2px solid #185A80;
    background-image: -webkit-linear-gradient(30deg, #185A80 50%, transparent 50%);
    background-image: linear-gradient(30deg, #185A80 50%, transparent 50%);
    background-size: 500px;
    background-repeat: no-repeat;
    background-position: 0%;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
}

.readmore-btn {
    color: #fff;
    border: 2px solid #d2b48c;
    background-image: -webkit-linear-gradient(30deg, #d2b48c 50%, transparent 50%);
    background-image: linear-gradient(30deg, #d2b48c 50%, transparent 50%);
    background-size: 500px;
    background-repeat: no-repeat;
    background-position: 0%;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
}

.readmore-btn:hover {
    background-position: 100%;
    color: #d2b48c;
}

.readmore:hover {
    background-position: 100%;
    color: #185A80;
}

.modal-center {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}